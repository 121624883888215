'use client';
import { FC, useState } from 'react';
import DefaultPopover from '@/entities/DefaultPopover/DefaultPopover';
import { useGetNotifications } from '@/shared/hooks/useGetNotifications';
import { useGetNotViewedNotificationsCount } from '@/shared/hooks/useGetNotViewedNotificationsCount';
import { NotificationsList } from '@/features/Notifications/ui/NotificationsList';
import { NotificationsButton } from '@/features/Notifications/ui/NotificationsButton';
import { useLocale } from 'next-intl';
import clsx from 'clsx';
import { cva } from 'class-variance-authority';
import { useMedia } from 'react-use';
import useMeasure from 'react-use-measure';

interface Props {
  className?: string;
  popoverInnerClassName?: string;
  notificationsListClassName?: string;
}

const NotificationsPopover: FC<Props> = ({
  className,
  popoverInnerClassName,
  notificationsListClassName,
}) => {
  const locale = useLocale();

  /* TODO: в мобилке ограничивать высоту */
  const [popoverRef /*popoverMeasure*/] = useMeasure();

  const isSm = useMedia('(max-width: 767px)', false);

  const [isNotificationsPopoverOpen, setIsNotificationsPopoverOpen] =
    useState(false);

  const { isLoading: isNotViewedCountLoading } =
    useGetNotViewedNotificationsCount({ lang: locale });

  const { isLoading: isNotificationsLoading } = useGetNotifications({
    lang: locale,
  });

  const isLoading = isNotViewedCountLoading || isNotificationsLoading;

  const isOpen = isNotificationsPopoverOpen && !isLoading;

  return (
    <DefaultPopover
      ref={(instance) => popoverRef(instance?.popperElement ?? null)}
      className={clsx(cvaRoot(), className)}
      innerClassName={clsx(cvaPopoverInner(), popoverInnerClassName)}
      placement={isSm ? 'bottom' : 'bottom-start'}
      isOpen={isOpen}
      offset={[0, 20]}
      onOpen={() => setIsNotificationsPopoverOpen(true)}
      onClose={() => setIsNotificationsPopoverOpen(false)}
      render={
        <NotificationsList
          className={clsx(cvaNotificationsList(), notificationsListClassName)}
          style={{ maxHeight: '55rem' }}
        />
      }>
      <NotificationsButton isActive={isNotificationsPopoverOpen} />
    </DefaultPopover>
  );
};

const cvaRoot = cva([
  'NotificationsPopover-cvaRoot',
  '!z-50',
  'w-[32rem] sm-max:w-full',
  'sm-max:px-1.6',
]);

const cvaPopoverInner = cva(['NotificationsPopover-cvaPopoverInner', '!p-0']);

const cvaNotificationsList = cva([
  'NotificationsPopover-cvaNotificationsList',
  'overflow-y-scroll without-scrollbar',
]);

export default NotificationsPopover;
