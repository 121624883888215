'use client';
import { ChangeEventHandler, DragEventHandler, FC, useEffect } from 'react';
import { cva } from 'class-variance-authority';
import { Transition } from 'framer-motion';
import { Popup, PopupAnimation } from '@/shared/ui/Popup/Popup';
import useGetMe from '@/shared/hooks/useGetMe';
import Button from '@/shared/ui/Button/Button';
import PlusIcon from '~/icons/plus-circle.svg';
import AddFile from '@/entities/AddFile/AddFile';
import useAddImage from '@/shared/hooks/useAddImage';
import { useImagesStore } from '@/shared/store/addImageProfileStore';
import { useLocalizedPathname } from '@/shared/i18n/routing';
import { useLocale } from 'next-intl';
import { useHeaderStore } from '@/shared/store/headerStore';
import { useMedia } from 'react-use';
import HeaderButtonsBurger from '@/widgets/Header/ui/HeaderButtons/HeaderButtonsBurger';
import { useGetHeaderMenu } from '@/shared/hooks/useGetHeaderMenu';
import { useGetHeaderOptions } from '@/shared/hooks/useGetHeaderOptions';

interface Props {}

const Burger: FC<Props> = ({}) => {
  const locale = useLocale();
  const pathname = useLocalizedPathname();

  const { data: headerMenu } = useGetHeaderMenu({ lang: locale });
  const { data: headerOptions } = useGetHeaderOptions({ lang: locale });

  const { isAuth } = useGetMe({ lang: locale });

  const onClose = useHeaderStore((state) => () => state.setIsBurgerOpen(false));

  const { handleDrop, handleFileChange } = useAddImage(useImagesStore, {
    redirect: '/profile/add-photo',
  });

  const onFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    handleFileChange(e);

    if (e.target.files?.length) {
      onClose();
    }
  };

  const onDrop: DragEventHandler<HTMLLabelElement> = (e) => {
    handleDrop(e);

    if (e.dataTransfer.files.length) {
      onClose();
    }
  };

  const isMd = useMedia('(max-width: 1279px)', true);

  useEffect(() => {
    if (!isMd) onClose();
  }, [isMd]);

  useEffect(() => {
    return () => {
      onClose();
    };
  }, [pathname]);

  return (
    <Popup
      className={cvaRoot()}
      wrapperClassName={cvaPopupWrapper()}
      animations={{
        overlay: { variants, transition },
        wrapper: { variants, transition },
      }}>
      <div className={cvaContainer()}>
        <div className={cvaHeader({ isAuth })}>
          <HeaderButtonsBurger />
        </div>

        <div className={cvaBody()}>
          {headerMenu?.items.map((i) => {
            if (i.acf.is_vote_item) {
              return (
                <Button
                  key={i.ID}
                  className={cvaButton({ isActive: pathname === '/' })}
                  href={'/'}
                  theme={'gray'}
                  stretched>
                  {i.title}
                </Button>
              );
            } else if (i.acf.is_contest_item) {
              return (
                <Button
                  key={i.ID}
                  className={cvaButton({
                    isActive: pathname.startsWith('/contest'),
                  })}
                  href={'/contest'}
                  theme={'gray'}
                  stretched>
                  {i.title}
                </Button>
              );
            } else if (i.slug) {
              return (
                <Button
                  key={i.ID}
                  className={cvaButton({
                    isActive: pathname === `/${i.slug}`,
                  })}
                  href={'/elo'}
                  theme={'gray'}
                  stretched>
                  {i.title}
                </Button>
              );
            } else if (!i.acf.is_vote_item && !i.acf.is_contest_item) {
              return (
                <Button
                  key={i.ID}
                  className={cvaButton({
                    isActive: pathname === '/popular',
                  })}
                  href={'/popular'}
                  theme={'gray'}
                  stretched>
                  {i.title}
                </Button>
              );
            }
          })}

          <Button
            className={cvaButton({
              isActive: pathname === '/search',
            })}
            href={'/search'}
            theme={'black'}
            stretched>
            {headerOptions?.settings_header_search.title}
          </Button>

          {isAuth ? (
            <AddFile
              onChange={onFileChange}
              onDrop={onDrop}
              className="cursor-pointer"
              multiple={false}>
              <Button
                className={cvaButton()}
                icon={<PlusIcon className={'w-2.4 h-2.4'} />}>
                {headerOptions?.settings_account.button_upload_text}
              </Button>
            </AddFile>
          ) : null}
        </div>
      </div>
    </Popup>
  );
};

const variants: PopupAnimation['variants'] = {
  open: {
    opacity: 1,
  },
  collapsed: {
    opacity: 0,
  },
};

const transition: Transition = {
  duration: 0.4,
  ease: 'easeOut',
};

const cvaRoot = cva(['Burger-cvaRoot', '!z-[49] overflow-y-auto']);

const cvaPopupWrapper = cva(['Burger-cvaPopupWrapper', 'w-full h-full']);

const cvaContainer = cva([
  'Burger-cvaContainer',
  'flex flex-col gap-1.2',
  'min-h-dvh',
  'pt-[calc(6.4rem+1.6rem)] pb-1.6 px-1.6',
  'bg-cBlack',
]);

const cvaHeader = cva(
  [
    'Burger-cvaHeader',
    'hidden justify-between items-start sm-max:flex',
    'empty:hidden',
  ],
  {
    variants: {
      isAuth: {
        true: 'px-1.2 py-0.8',
        false: 'pt-0.8',
      },
    },
  }
);

const cvaBody = cva([
  'Burger-cvaBody',
  'flex flex-col gap-1.2',
  'md-max:mt-1.6 sm-max:mt-0',
]);

const cvaButton = cva(['Burger-cvaButton', 'w-full', 'text-1.8-600'], {
  variants: {
    isActive: {
      true: '!bg-cBlack border-2 border-cPurple900',
    },
  },
});

export default Burger;
