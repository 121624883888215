import { CSSProperties, forwardRef, useEffect, useRef, useState } from 'react';
import { cva } from 'class-variance-authority';
import { Heading } from '@/shared/ui/Heading/Heading';
import { motion } from 'framer-motion';
import Notification from '@/features/Notifications/ui/Notification';
import clsx from 'clsx';
import { INotification } from '@/shared/api/notify/getNotifications/types';
import { useQueryClient } from '@tanstack/react-query';
import { useUpdateEffect } from 'react-use';
import { viewNotifications } from '@/shared/api/notify/viewNotifications';
import {
  getUseGetNotificationsQueryKey,
  useGetNotifications,
} from '@/shared/hooks/useGetNotifications';
import mergeRefs from 'merge-refs';
import { isCancel } from 'axios';
import { useLocale } from 'next-intl';
import { getUseGetNotViewedNotificationsCountQueryKey } from '@/shared/hooks/useGetNotViewedNotificationsCount';
import { useGetGlobalOptions } from '@/shared/hooks/useGetGlobalOptions';

interface Props {
  className?: string;
  style?: CSSProperties;
}

export const NotificationsList = forwardRef<HTMLDivElement, Props>(
  ({ className, style }, ref) => {
    const rootRef = useRef<HTMLDivElement | null>(null);

    const locale = useLocale();

    const { data: globalOptions } = useGetGlobalOptions({ lang: locale });

    const { data, fetchNextPage, isFetchingNextPage } = useGetNotifications({
      lang: locale,
    });

    const onLoadMore = async () => {
      if (!isFetchingNextPage) {
        try {
          await fetchNextPage();
        } catch (e) {
          console.error(e);
        }
      }
    };

    const [viewedIds, setViewedIds] = useState<number[]>([]);

    const queryClient = useQueryClient();
    const abortControllerRef = useRef(new AbortController());

    const onNotificationsView = async () => {
      try {
        await viewNotifications({
          notify_ids: viewedIds,
          lang: locale,
          signal: abortControllerRef.current.signal,
        });
        await queryClient.invalidateQueries({
          queryKey: getUseGetNotViewedNotificationsCountQueryKey({
            lang: locale,
          }),
        });
        await queryClient.invalidateQueries({
          queryKey: getUseGetNotificationsQueryKey({ lang: locale }),
        });
      } catch (e) {
        if (!isCancel(e)) {
          console.error(e);
        }
      }
    };

    useUpdateEffect(() => {
      void onNotificationsView();

      return () => {
        abortControllerRef.current.abort();
      };
    }, [viewedIds]);

    useEffect(() => {
      return () => {
        if (viewedIds.length) {
          void onNotificationsView();
        }
      };
    }, []);

    const onViewportEnter = (notification: INotification) => {
      if (!notification.is_viewed && !viewedIds.includes(notification.id)) {
        setViewedIds((prev) => [...prev, notification.id]);
      }
    };

    return (
      <div
        ref={mergeRefs(ref, rootRef)}
        className={clsx(cvaRoot(), className)}
        style={style}>
        <div className={cvaTop()}>
          <Heading size={'subtitle-sm'}>
            {globalOptions?.settings_notify.title}
          </Heading>
        </div>
        <div className={cvaNotifications()}>
          {!data?.length ? (
            <div className={cvaNotificationsEmpty()}>
              {globalOptions?.settings_notify.empty}
            </div>
          ) : null}

          {data?.map?.((el) => (
            <motion.div
              key={el.id}
              viewport={{ root: rootRef }}
              onViewportEnter={() => onViewportEnter(el)}>
              <Notification item={el} />
            </motion.div>
          ))}

          <motion.div
            key={data?.length ?? 0}
            className={cvaLoadMoreTrigger()}
            viewport={{ root: rootRef }}
            onViewportEnter={onLoadMore}
          />
        </div>
      </div>
    );
  }
);

NotificationsList.displayName = 'NotificationsList';

const cvaRoot = cva([
  'NotificationsList-cvaRoot',
  'pt-0 pb-1.2 px-1.2',
  'rounded-[2.4rem] md-max:rounded-[1.6rem]',
  'bg-cGray1100',
]);

const cvaTop = cva([
  'NotificationsList-cvaTop',
  'sticky top-0 z-[1]',
  'py-1.6 px-0.8 mb-1.2',
  'flex justify-between items-center',
  'bg-cGray1100',
]);

const cvaNotifications = cva([
  'NotificationsList-cvaNotifications',
  'relative',
  'flex flex-col gap-1.6',
]);

const cvaNotificationsEmpty = cva([
  'NotificationsList-cvaNotificationsEmpty',
  'flex-grow',
  'py-0.4 px-0.8 -mt-1.2',
  'text-1.8-600',
]);

const cvaLoadMoreTrigger = cva([
  'NotificationsList-cvaLoadMoreTrigger',
  'absolute -z-[9999] bottom-[10rem] left-1/2 -translate-x-1/2',
  'w-1 h-1',
  'invisible pointer-events-none',
]);
