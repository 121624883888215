import { FC } from 'react';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { useHeaderStore } from '@/shared/store/headerStore';

interface Props {
  className?: string;
}

export const BurgerButton: FC<Props> = ({ className }) => {
  const { isBurgerOpen, setIsBurgerOpen } = useHeaderStore();

  return (
    <button
      className={clsx(cvaRoot(), className)}
      type={'button'}
      onClick={() => setIsBurgerOpen(!isBurgerOpen)}>
      <div className={cvaWrapper()}>
        <span className={cvaBurgerItem({ isOpen: isBurgerOpen })}></span>
        <span className={cvaBurgerItem({ isOpen: isBurgerOpen })}></span>
        <span className={cvaBurgerItem({ isOpen: isBurgerOpen })}></span>
      </div>
    </button>
  );
};

const cvaRoot = cva([
  'BurgerButton-cvaRoot',
  'flex justify-center items-center',
  'grow-0 shrink-0 w-4.8 h-4.8',
  'bg-cGray900',
  'rounded-[50%]',
]);

const cvaWrapper = cva(['BurgerButton-cvaWrapper', 'relative', 'w-2 h-1.4']);

const cvaBurgerItem = cva(
  [
    'BurgerButton-cvaBurgerItem',
    'absolute left-0 right-0 h-0.3',
    'bg-white',
    'rounded-[1rem] first:top-0 last:bottom-0 child-2:absolute-center-y',
    'transition-all duration-400',
  ],
  {
    variants: {
      isOpen: {
        true: [
          'child-1:rotate-45 child-1:translate-y-[5.4px]',
          'child-2:opacity-0 child-2:invisible',
          'child-3:-rotate-45 child-3:-translate-y-[5.4px]',
        ],
      },
    },
  }
);
