'use client';
import { FC, useMemo } from 'react';
import { cva } from 'class-variance-authority';
import { useLocalizedPathname } from '@/shared/i18n/routing';
import LangIcon from '~/icons/language.svg';
import clsx from 'clsx';
import { useGetLocalesConfig } from '@/shared/hooks/useGetLocalesConfig';
import { useLocale } from 'next-intl';
import { useSearchParams } from 'next/navigation';
import Link from 'next/link';

interface Props {
  className?: string;
}

export const LocaleSwitcher: FC<Props> = ({ className }) => {
  const locale = useLocale();
  const pathname = useLocalizedPathname();
  const searchParams = useSearchParams();

  const { data: localesConfig } = useGetLocalesConfig();

  const localeData = useMemo(() => {
    const locales = localesConfig?.locales ?? [];

    const currentLocaleIndex = locales.findIndex((i) => i.code === locale);

    if (currentLocaleIndex !== -1) {
      const currentLocale = locales[currentLocaleIndex];
      let nextLocale: (typeof locales)[0] | null =
        locales[currentLocaleIndex + 1] ?? locales[0];

      if (nextLocale === currentLocale) {
        nextLocale = null;
      }

      return {
        current: currentLocale,
        next: nextLocale,
      };
    }
  }, [locale, localesConfig?.locales]);

  const href = useMemo(() => {
    let localeCode = localeData?.next?.code ?? localeData?.current.code ?? '';

    if (localeCode === localesConfig?.defaultLocale) {
      localeCode = '';
    }

    return (
      (localeCode ? `/${localeCode}` : '') +
      pathname +
      (searchParams.toString() ? `?${searchParams.toString()}` : '')
    );
  }, [localeData, localesConfig, pathname, searchParams]);

  return (
    <>
      {localeData ? (
        <Link
          className={clsx(cvaRoot(), className)}
          href={href}
          hrefLang={localeData.next?.code ?? localeData.current.code}>
          <LangIcon className={'w-2.4 h-2.4'} />
          {localeData.current.label}
        </Link>
      ) : null}
    </>
  );
};

const cvaRoot = cva([
  'LocaleSwitcher-cvaRoot',
  'text-cGray300 text-1.4-500',
  'flex items-center gap-0.8',
]);
