'use client';
import Container from '@/shared/ui/Container/ui/Container';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { ComponentProps, forwardRef, ReactNode } from 'react';
import HeaderNavigation from './HeaderNavigation/HeaderNavigation';
import Burger from './Burger';
import { AnimatePresence, motion } from 'framer-motion';
import Logo from '@/entities/Logo/Logo';
import useMeasure from 'react-use-measure';
import { Portal } from '@/shared/ui/Portal/Portal';
import { BurgerButton } from '@/widgets/Header/ui/BurgerButton';
import { useLocalizedPathname } from '@/shared/i18n/routing';
import { useHeaderStore } from '@/shared/store/headerStore';

interface HeaderProps {
  className?: string;
  buttonsSlot?: ReactNode;
  afterSlot?: ReactNode;
}

const Header = forwardRef<HTMLDivElement, HeaderProps>(
  ({ className, buttonsSlot, afterSlot }, ref) => {
    const pathname = useLocalizedPathname();

    const [headerRef, headerMeasure] = useMeasure();
    const [logoRef, logoMeasure] = useMeasure();
    const [headerNavigationRef, headerNavigationMeasure] = useMeasure();

    const spacerBasis =
      headerMeasure.width / 2 -
      logoMeasure.width -
      headerNavigationMeasure.width / 2 -
      24;

    const { isBurgerOpen, setIsBurgerOpen } = useHeaderStore();

    const isVisibleButtonsSlotOnMobile = pathname.includes('/profile');

    return (
      <Container
        ref={ref}
        className={clsx(cvaRoot(), className)}
        size={'xl'}
        gutters={'xl'}>
        <header ref={headerRef} className={cvaHeader()}>
          <Logo
            ref={logoRef}
            logoLabelClassName={cvaLogoLabel({ isBurgerOpen })}
            onClick={() => setIsBurgerOpen(false)}
          />

          <div
            className={'grow-0 shrink md-max:hidden'}
            style={{ flexBasis: spacerBasis }}
          />
          <HeaderNavigation
            ref={headerNavigationRef}
            className={cvaHeaderNavigation({
              isVisibleButtonsSlotOnMobile,
              isBurgerOpen,
            })}
            listEndSlot={
              <div className={'hidden px-0.8 md-max:block sm-max:hidden'}>
                <BurgerButton className={'!bg-cBaseBlack'} />
              </div>
            }
          />
          <div className={'flex-1 md-max:hidden'} />

          <div
            className={cvaButtonsSlotWrapper({
              isVisibleButtonsSlotOnMobile,
              isBurgerOpen,
            })}>
            {buttonsSlot}
          </div>

          <BurgerButton className={'hidden sm-max:flex'} />

          <AnimatePresence>
            {isBurgerOpen && (
              <Portal>
                <Burger />
              </Portal>
            )}
          </AnimatePresence>
        </header>

        <AnimatePresence initial={false}>
          {!isBurgerOpen ? (
            <motion.div {...afterSlotWrapperAnimation}>{afterSlot}</motion.div>
          ) : null}
        </AnimatePresence>
      </Container>
    );
  }
);

Header.displayName = 'Header';

const afterSlotWrapperAnimation: ComponentProps<typeof motion.div> = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { delay: 0.1 } },
  exit: { opacity: 0, transitionEnd: { display: 'none' } },
  transition: { duration: 0.3, ease: 'easeOut' },
};

const cvaRoot = cva([
  'Header-cvaRoot',
  'sticky top-0',
  'w-full',
  'z-50',
  'py-1.2 md-max:py-0.4',
]);

const cvaHeader = cva([
  'Header-cvaHeader',
  'flex justify-between items-center gap-1.2 md-max:gap-2.4 sm-max:gap-[0.98rem]',
]);

const cvaLogoLabel = cva(
  ['Header-cvaLogoLabel', 'transition-opacity duration-300'],
  {
    variants: {
      isBurgerOpen: {
        true: 'delay-100 opacity-100 sm-max:!visible',
        false: 'opacity-100 sm-max:opacity-0',
      },
    },
  }
);

const cvaHeaderNavigation = cva(
  [
    'Header-cvaHeaderNavigation',
    'transition-[opacity,visibility] duration-300',
  ],
  {
    variants: {
      isVisibleButtonsSlotOnMobile: {
        true: 'sm-max:hidden',
      },
      isBurgerOpen: {
        true: 'sm-max:opacity-0 sm-max:invisible',
        false: 'sm-max:opacity-100 sm-max:visible sm-max:delay-100',
      },
    },
  }
);

const cvaButtonsSlotWrapper = cva(
  [
    'Header-cvaButtonsSlotWrapper',
    'self-stretch sm-max:flex-1',
    'transition-[opacity,visibility] duration-300',
  ],
  {
    variants: {
      isVisibleButtonsSlotOnMobile: {
        true: 'sm-max:block',
        false: 'sm-max:hidden',
      },
      isBurgerOpen: {
        true: 'sm-max:opacity-0 sm-max:invisible',
        false: 'sm-max:opacity-100 sm-max:visible sm-max:delay-100',
      },
    },
  }
);

export default Header;
