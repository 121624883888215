'use client';
import { FC } from 'react';
import FavoritesIcon from '~/icons/favorites-fill.svg';
import WrapperIcon from '@/shared/ui/WrapperIcon/WrapperIcon';
import Avatar from '@/entities/Avatar/Avatar';
import NotificationsPopover from '@/features/Notifications/ui/NotificationsPopover';
import useGetMe from '@/shared/hooks/useGetMe';
import HeaderButtonsBase from '@/widgets/Header/ui/HeaderButtons/HeaderButtonsBase';
import { LocalizedLink } from '@/shared/i18n/routing';
import { useLocale } from 'next-intl';
import { AddPhotoButton } from '@/widgets/Header/ui/HeaderButtons/AddPhotoButton';

interface Props {}

const HeaderButtonsDefault: FC<Props> = () => {
  const locale = useLocale();

  const { isAuth, data: me } = useGetMe({ lang: locale });

  return (
    <HeaderButtonsBase>
      <NotificationsPopover />
      <WrapperIcon className={'shadow-menu'} href={'/favorites'}>
        <FavoritesIcon />
      </WrapperIcon>
      <LocalizedLink href={isAuth ? `/profile/${me!.result!.ID}` : '/profile'}>
        <Avatar
          className={'shadow-menu'}
          src={me?.result?.data?.avatar?.[0]}
          size={64}
        />
      </LocalizedLink>
      <AddPhotoButton />
    </HeaderButtonsBase>
  );
};

export default HeaderButtonsDefault;
