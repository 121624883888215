import { QueryClient, useQuery } from '@tanstack/react-query';
import { getHeaderOptions } from '@/shared/api/options/getHeaderOptions';

type Params = Parameters<typeof getHeaderOptions>[0];

export const useGetHeaderOptions = (params: Params) => {
  return useQuery({
    queryKey: getUseGetHeaderOptionsQueryKey(params),
    queryFn: () => getHeaderOptions(params),
    staleTime: Infinity,
  });
};

export const prefetchGetHeaderOptions = async (
  queryClient: QueryClient,
  params: Params
) => {
  await queryClient.prefetchQuery({
    queryKey: getUseGetHeaderOptionsQueryKey(params),
    queryFn: () => getHeaderOptions(params),
  });
};

export const getUseGetHeaderOptionsQueryKey = (params: Params) => [
  'getHeaderOptions',
  params,
];
