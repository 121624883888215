import { FC } from 'react';
import NavLink from '@/entities/NavLink/NavLink';
import { useLocalizedPathname } from '@/shared/i18n/routing';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';

interface Props {
  className?: string;
  title: string;
  href: string;
}

export const StaticPageNavLink: FC<Props> = ({ className, title, href }) => {
  const pathname = useLocalizedPathname();
  const isActive = pathname === href;
  const isVisibleOnMobile = isActive;

  return (
    <NavLink
      className={clsx(cvaRoot({ isVisibleOnMobile }), className)}
      item={{
        title,
        href,
        isActive,
      }}
    />
  );
};

const cvaRoot = cva(['StaticPageNavLink-cvaRoot'], {
  variants: {
    isVisibleOnMobile: {
      true: 'md-max:flex',
      false: 'md-max:hidden',
    },
  },
});
