import { FC, FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { cva } from 'class-variance-authority';
import CloseIcon from '~/icons/close-white.svg';
import WrapperIcon from '@/shared/ui/WrapperIcon/WrapperIcon';
import SearchIcon from '~/icons/search.svg';
import useQueryParam from '@/shared/hooks/useQueryParam';
import { useClickAway } from 'react-use';
import {
  useLocalizedPathname,
  useLocalizedRouter,
} from '@/shared/i18n/routing';
import { useGetHeaderOptions } from '@/shared/hooks/useGetHeaderOptions';
import { useLocale } from 'next-intl';

interface Props {}

const Search: FC<Props> = ({}) => {
  const ref = useRef<HTMLDivElement>(null);

  const router = useLocalizedRouter();
  const locale = useLocale();
  const pathname = useLocalizedPathname();

  const { data: headerOptions } = useGetHeaderOptions({ lang: locale });

  const [query] = useQueryParam<string | undefined>('query');
  const [from] = useQueryParam('from');

  const [isExpanded, setIsExpanded] = useState(false);

  const isActive = pathname === '/search';

  const [value, setValue] = useState(isActive ? query : '');

  const isFilled = useMemo(() => {
    return isActive && Boolean(query) && !isExpanded;
  }, [isActive, query, isExpanded]);

  const onSubmit = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    const searchParams = new URLSearchParams();

    const trimValue = (value ?? '').trim();
    const fromPathname = !isActive ? pathname : (from as string);

    if (trimValue) {
      searchParams.set('query', trimValue);
      fromPathname && searchParams.set('from', fromPathname);

      const scroll = query !== trimValue;

      router.push(`/search?${searchParams.toString()}`, { scroll });
    }
  };

  const onReset = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    setIsExpanded(false);
    setValue('');

    if (isActive) {
      router.push((from as string) ?? '/');
    }
  };

  useEffect(() => {
    if (!isActive) {
      onReset();
    }
  }, [pathname]);

  useClickAway(ref, () => {
    if (isActive ? isExpanded : isExpanded || value) {
      if (!isActive) {
        setIsExpanded(Boolean(value));
      } else {
        if (value) {
          onSubmit();
          setIsExpanded(false);
        } else {
          onReset();
        }
      }
    }
  });

  return (
    <div
      ref={ref}
      className={cvaRoot({ isExpanded, isFilled, isActive })}
      onClick={() => setIsExpanded(true)}>
      <div
        className={cvaTitle({
          isEmpty: pathname === '/search' ? false : !value,
        })}>
        {isFilled ? query ?? '' : headerOptions?.settings_header_search.title}
      </div>

      <WrapperIcon
        className={cvaIcon({ isFilled })}
        isStatic={!isFilled}
        theme={isFilled ? 'inverse' : 'black'}
        onClick={
          isFilled
            ? (e) => {
                e.stopPropagation();
                onReset();
              }
            : undefined
        }>
        {isFilled ? <CloseIcon /> : <SearchIcon />}
      </WrapperIcon>

      {(pathname === '/search' ? isExpanded : isExpanded || value) && (
        <form className={cvaForm()} onSubmit={onSubmit} onReset={onReset}>
          <input
            className={cvaInput()}
            type="text"
            value={value}
            autoFocus
            onChange={(e) => setValue(e.target.value)}
          />
          <WrapperIcon
            className={cvaResetFiled()}
            theme={'black'}
            type={'reset'}
            onFocus={(e) => e.stopPropagation()}>
            <CloseIcon />
          </WrapperIcon>
        </form>
      )}
    </div>
  );
};

const cvaRoot = cva(
  [
    'Search-cvaRoot',
    'flex items-center md-max:hidden',
    'md-max:flex-1',
    'p-0.8 sm-max:p-0.4',
    'before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 md-max:before:hidden',
    'before:w-0.2 before:h-1.6 before:bg-cWhite before:opacity-15 before:rounded-lg',
  ],
  {
    variants: {
      isExpanded: {
        true: 'static before:hidden',
        false: 'relative',
      },
      isFilled: {
        true: 'before:hidden after:absolute after:z-[1] after:top-0 after:left-0 after:w-full after:h-full after:rounded-[4.8rem] after:bg-cPurple700',
      },
      isActive: {
        true: 'md-max:!flex',
      },
    },
  }
);

const cvaTitle = cva(
  [
    'Search-cvaTitle',
    'relative z-[2]',
    'w-5.4 basis-5.4 grow-0 shrink-0 md-max:basis-auto md-max:flex-1',
    'my-1.3 ml-1.6 mr-1.2',
    'text-1.6-600',
    'line-clamp-1 break-all',
  ],
  {
    variants: {
      isEmpty: {
        true: 'text-cGray300',
        false: 'text-cWhite',
      },
    },
  }
);

const cvaIcon = cva(
  [
    'Search-cvaIcon',
    'relative z-[2]',
    'basis-4.8 grow-0 shrink-0',
    'cursor-pointer',
  ],
  {
    variants: {
      isFilled: {
        true: '[&_path]:fill-cPurple700',
        false: '',
      },
    },
  }
);

const cvaForm = cva([
  'Search-cvaForm',
  'absolute z-[2] left-0 top-0',
  'w-full h-full',
]);

const cvaInput = cva([
  'Search-cvaInput',
  'w-full h-full',
  'px-2.4 pr-[calc(2.4rem+4.8rem)]',
  'rounded-[2.4rem] outline outline-2 outline-cPurple700',
  'bg-cGray1300',
]);

const cvaResetFiled = cva([
  'Search-cvaResetFiled',
  'absolute-center-y right-0.8 z-[2]',
]);

export default Search;
