import { API } from '../../api';
import { axiosInstance } from '../../axios';
import { IFooterMenu } from '@/shared/api/menus/getFooterMenu/types';

interface Params {
  lang: string;
}

export const getFooterMenu = async (params: Params) => {
  const result = await axiosInstance.get<IFooterMenu>(API.getFooterMenu, {
    params: {
      acf_format: 'standard',
      ...params,
    },
  });

  return result.data;
};
