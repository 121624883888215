import { API } from '../../api';
import { axiosInstance } from '../../axios';
import { IOurProjectsMenu } from '@/shared/api/menus/getOurProjectsMenu/types';

interface Params {
  lang: string;
}

export const getOurProjectsMenu = async (params: Params) => {
  const result = await axiosInstance.get<IOurProjectsMenu>(
    API.getOurProjectsMenu,
    {
      params: {
        acf_format: 'standard',
        ...params,
      },
    }
  );

  return result.data;
};
