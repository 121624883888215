import { FC } from 'react';
import { cva } from 'class-variance-authority';
import Button from '@/shared/ui/Button/Button';
import PlusIcon from '~/icons/plus-circle.svg';
import AddFile from '@/entities/AddFile/AddFile';
import useAddImage from '@/shared/hooks/useAddImage';
import { useImagesStore } from '@/shared/store/addImageProfileStore';
import { useMedia } from 'react-use';
import { useGetHeaderOptions } from '@/shared/hooks/useGetHeaderOptions';
import { useLocale } from 'next-intl';

interface Props {}

export const AddPhotoButton: FC<Props> = ({}) => {
  const locale = useLocale();

  const isSm = useMedia('(max-width: 767px)', false);

  const { data: headerOptions } = useGetHeaderOptions({ lang: locale });

  const { handleDrop, handleFileChange } = useAddImage(useImagesStore, {
    redirect: '/profile/add-photo',
  });

  return (
    <AddFile
      className={'cursor-pointer'}
      onChange={handleFileChange}
      onDrop={handleDrop}
      multiple={!isSm}>
      <Button
        className={cvaRoot()}
        innerClassName={'inline-flex items-center'}
        innerRowClassName={'inline-flex items-center'}
        theme={'primary'}>
        <PlusIcon className={'grow-0 shrink-0 w-2.4 h-2.4'} />
        <span className={'inline px-0.8 whitespace-nowrap xl-max:hidden'}>
          {headerOptions?.settings_account.button_upload_text}
        </span>
      </Button>
    </AddFile>
  );
};

const cvaRoot = cva([
  'AddPhotoButton-cvaRoot',
  'md:gap-0.8',
  '!p-1.2',
  'text-1.6-600',
  'shadow-menu',
]);
