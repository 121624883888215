'use client';
import { forwardRef, MouseEventHandler } from 'react';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { useGetHeaderOptions } from '@/shared/hooks/useGetHeaderOptions';
import Image from 'next/image';
import { useLocale } from 'next-intl';
import { LocalizedLink } from '@/shared/i18n/routing';
import useGetMe from '@/shared/hooks/useGetMe';

interface Props {
  className?: string;
  logoClassName?: string;
  logoLabelClassName?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const Logo = forwardRef<HTMLAnchorElement, Props>(
  ({ className, logoClassName, logoLabelClassName, onClick }, ref) => {
    const locale = useLocale();
    const { data: headerOptions } = useGetHeaderOptions({ lang: locale });

    const { isAuth } = useGetMe({ lang: locale });

    return (
      <LocalizedLink
        ref={ref}
        className={clsx(cvaHeaderLogo(), className)}
        href={'/'}
        onClick={onClick}>
        {headerOptions?.settings_header_logo ? (
          <Image
            className={clsx(cvaLogoIcon(), logoClassName)}
            src={headerOptions?.settings_header_logo.url}
            alt={headerOptions?.settings_header_logo.alt ?? ''}
            width={headerOptions?.settings_header_logo.width}
            height={headerOptions?.settings_header_logo.height}
            quality={100}
            priority
          />
        ) : null}

        {headerOptions?.settings_header_title ? (
          <div className={clsx(cvaLogoLabel({ isAuth }), logoLabelClassName)}>
            <span>{headerOptions?.settings_header_title}</span>
          </div>
        ) : null}
      </LocalizedLink>
    );
  }
);

Logo.displayName = 'Logo';

const cvaHeaderLogo = cva([
  'Logo-cvaHeaderLogo',
  'relative inline-flex gap-1.6 items-center',
  'flex-grow-0 flex-shrink-0 basis-auto',
]);

const cvaLogoIcon = cva(['Logo-cvaLogoIcon', 'w-4.8 h-4.8']);

const cvaLogoLabel = cva(
  [
    'Logo-cvaLogoLabel',
    'relative md-max:absolute-center-y md-max:left-0',
    'flex items-center',
    'w-auto h-full md-max:w-[calc(4.8rem+1.2rem+10rem)]',
    'md-max:pl-[calc(4.8rem+1.2rem)]',
    'visible md-max:invisible',
    'font-secondary font-semibold text-[1.653rem] leading-[2.45rem] -tracking-[0.02em]',
  ],
  {
    variants: {
      isAuth: {
        true: 'xl-max:hidden sm-max:flex',
        false: '',
      },
    },
  }
);

export default Logo;
