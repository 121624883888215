import { API } from '../../api';
import { axiosInstance } from '../../axios';
import { INotification } from '@/shared/api/notify/getNotifications/types';

interface Params {
  limit?: number;
  paged?: number;
  lang: string;
}

export const getNotifications = async ({
  limit = 20,
  paged = 1,
  lang,
}: Params) => {
  const result = await axiosInstance.get<Response>(API.getNotifications, {
    params: {
      limit,
      paged,
      lang,
    },
  });

  return result.data;
};

interface Response {
  result: {
    notifies: INotification[];
    count: number;
    pages: number;
  };
}
