import { useInfiniteQuery } from '@tanstack/react-query';
import { getNotifications } from '@/shared/api/notify/getNotifications';

type Params = Omit<Parameters<typeof getNotifications>[0], 'paged' | 'limit'>;

/* TODO: gcTime: 0 ? */
export const useGetNotifications = (params: Params) => {
  const limit = 20;

  return useInfiniteQuery({
    queryFn: ({ pageParam }) =>
      getNotifications({ paged: pageParam, limit, ...params }),
    queryKey: getUseGetNotificationsQueryKey(params),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, lastPageParam) =>
      lastPage.result?.pages > lastPageParam ? lastPageParam + 1 : null,
    getPreviousPageParam: (_, __, lastPageParam) =>
      lastPageParam === 1 ? null : lastPageParam - 1,
    select: (data) => {
      return data.pages.map((i) => i.result?.notifies)?.flat?.() ?? [];
    },
  });
};

export const getUseGetNotificationsQueryKey = (params: Params) => [
  'getNotifications',
  params,
];
