import { ChangeEvent, DragEvent } from 'react';
import randomstring from 'randomstring';
import { StoreApi, UseBoundStore } from 'zustand';
import { IAddImage, IImageStore } from '../store/createImagesStore';
import { getFileImageDimensions } from '@/shared/utils/getFileImageDimensions';
import { useLocalizedRouter } from '@/shared/i18n/routing';

type Props = {
  redirect?: string;
  isSelect?: boolean;
  maxPhotos?: number;
};

export default function useAddImage(
  useStore: UseBoundStore<StoreApi<IImageStore<IAddImage>>>,
  params?: Props
) {
  const router = useLocalizedRouter();
  const { updateImages, images } = useStore();
  const sliceNum = params?.maxPhotos ?? Infinity;

  const onAddImage = async (file: File) => {
    const dimensions = await getFileImageDimensions(file);

    const newImage: IAddImage = {
      isSelect: params?.isSelect ?? true,
      id: randomstring.generate(12),
      tags: [],
      comment: '',
      is18Category: false,
      category: null,
      file,
      width: dimensions.width,
      height: dimensions.height,
    };

    updateImages(newImage);

    if (params?.redirect) {
      router.push(params.redirect);
    }
  };

  const handleDrop = (event: DragEvent<HTMLElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;

    if (files && files.length && images.length < sliceNum) {
      Array.from(files)
        .slice(0, sliceNum)
        .forEach((file) => {
          if (file.type === 'image/png' || file.type === 'image/jpeg') {
            void onAddImage(file);
          }
        });
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length && images.length < sliceNum) {
      Array.from(files)
        .slice(0, sliceNum)
        .forEach((file) => {
          if (file.type === 'image/png' || file.type === 'image/jpeg') {
            void onAddImage(file);
          }
        });

      event.target.value = '';
    }
  };

  return {
    onAddImage,
    handleDrop,
    handleFileChange,
  };
}
