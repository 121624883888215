import { QueryClient, useQuery } from '@tanstack/react-query';
import { getFooterOptions } from '@/shared/api/options/getFooterOptions';

type Params = Parameters<typeof getFooterOptions>[0];

export const useGetFooterOptions = (params: Params) => {
  return useQuery({
    queryKey: getUseGetFooterOptionsQueryKey(params),
    queryFn: () => getFooterOptions(params),
    staleTime: Infinity,
  });
};

export const prefetchGetFooterOptions = async (
  queryClient: QueryClient,
  params: Params
) => {
  await queryClient.prefetchQuery({
    queryKey: getUseGetFooterOptionsQueryKey(params),
    queryFn: () => getFooterOptions(params),
  });
};

export const getUseGetFooterOptionsQueryKey = (params: Params) => [
  'getFooterOptions',
  params,
];
