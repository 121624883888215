export const getFileImageDimensions = async (file: File) => {
  const img = new Image();
  img.src = URL.createObjectURL(file);

  await img.decode();

  const width = img.naturalWidth;
  const height = img.naturalHeight;

  return { width, height };
};
