import { API } from '../../api';
import { axiosInstance } from '../../axios';

interface Params {
  lang: string;
}

export const logout = async (params: Params) => {
  const result = await axiosInstance.get(API.logout, { params });
  return result.data;
};
