import { forwardRef } from 'react';
import { cva } from 'class-variance-authority';
import WrapperIcon from '@/shared/ui/WrapperIcon/WrapperIcon';
import NotificationIcon from '~/icons/notification.svg';
import NotificationFilledIcon from '~/icons/notification-filled.svg';
import Button from '@/shared/ui/Button/Button';
import { useGetNotViewedNotificationsCount } from '@/shared/hooks/useGetNotViewedNotificationsCount';
import { useGetNotifications } from '@/shared/hooks/useGetNotifications';
import clsx from 'clsx';
import { useLocale } from 'next-intl';

interface Props {
  className?: string;
  isActive?: boolean;
}

export const NotificationsButton = forwardRef<HTMLButtonElement, Props>(
  ({ className, isActive }, ref) => {
    const locale = useLocale();

    const { data: notViewedCount, isLoading: isNotViewedCountLoading } =
      useGetNotViewedNotificationsCount({ lang: locale });

    const { isLoading: isNotificationsLoading } = useGetNotifications({
      lang: locale,
    });

    const isLoading = isNotViewedCountLoading || isNotificationsLoading;

    const theme = isActive ? 'focus' : notViewedCount ? 'inverse' : 'gray';

    return (
      <Button
        innerRef={ref}
        className={clsx(cvaRoot(), className, 'shadow-menu')}
        size={'fit'}
        theme={theme}
        isLoading={isLoading}>
        <WrapperIcon theme={theme} isStatic>
          {!isLoading ? (
            <>
              <NotificationIcon
                className={cvaIcon({
                  filled: isActive ? false : undefined,
                  theme,
                })}
              />
              <NotificationFilledIcon
                className={cvaIcon({
                  filled: isActive || theme === 'inverse',
                })}
              />
            </>
          ) : null}
        </WrapperIcon>
      </Button>
    );
  }
);

NotificationsButton.displayName = 'NotificationsButton';

const cvaRoot = cva(['NotificationsButton-cvaRoot', 'grow-0 shrink-0 ']);

const cvaIcon = cva(
  ['NotificationsButton-cvaIcon', 'transition-[color,opacity] duration-300'],
  {
    variants: {
      filled: {
        true: 'text-cPurple500 opacity-100',
        false: 'text-cWhite opacity-0',
      },
      theme: {
        inverse: 'text-cPurple700',
        gray: '',
        focus: '',
      },
    },
  }
);
