import { axiosInstance } from '@/shared/api/axios';
import { API } from '@/shared/api/api';
import { IFooterOptions } from '@/shared/api/options/getFooterOptions/types';

interface Params {
  lang: string;
}

export const getFooterOptions = async (params: Params) => {
  const response = await axiosInstance.get<IFooterOptions>(
    API.getFooterOptions,
    {
      params,
    }
  );

  return response.data;
};
