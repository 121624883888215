import { getOAuthURL } from '@/shared/api/auth/getOauthURL';
import { useQuery } from '@tanstack/react-query';

type Params = Parameters<typeof getOAuthURL>[0];

interface Options {
  enabled?: boolean;
}

export const useGetOAuthURL = (params: Params, options: Options) => {
  return useQuery({
    queryKey: getUseGetOAuthURLQueryKey(params),
    queryFn: () => getOAuthURL(params),
    ...options,
  });
};

export const getUseGetOAuthURLQueryKey = (params: Params) => [
  'getOAuthURL',
  params,
];
