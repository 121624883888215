'use client';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { forwardRef, ReactNode } from 'react';
import Search from '@/features/Search/ui/Search';
import { VoteNavLink } from '@/widgets/Header/ui/HeaderNavigation/VoteNavLink';
import { PopularNavLink } from '@/widgets/Header/ui/HeaderNavigation/PopularNavLink';
import { ContestNavLink } from '@/widgets/Header/ui/HeaderNavigation/ContestNavLink';
import { StaticPageNavLink } from '@/widgets/Header/ui/HeaderNavigation/StaticPageNavLink';
import { useGetHeaderMenu } from '@/shared/hooks/useGetHeaderMenu';
import { useLocale } from 'next-intl';
import { motion } from 'framer-motion';

interface HeaderNavigationProps {
  className?: string;
  listEndSlot?: ReactNode;
}

const HeaderNavigation = forwardRef<HTMLElement, HeaderNavigationProps>(
  ({ className, listEndSlot }, ref) => {
    const locale = useLocale();

    const { data: headerMenu } = useGetHeaderMenu({ lang: locale });

    return (
      <motion.nav ref={ref} className={clsx(cvaRoot(), className)}>
        <div className={cvaList()}>
          {headerMenu?.items.map((i) => {
            if (i.acf.is_vote_item) {
              return <VoteNavLink key={i.ID} title={i.title} />;
            } else if (i.acf.is_contest_item) {
              return <ContestNavLink key={i.ID} title={i.title} />;
            } else if (i.slug) {
              return (
                <StaticPageNavLink
                  key={i.ID}
                  title={i.title}
                  href={`/${i.slug}`}
                />
              );
            } else if (!i.acf.is_vote_item && !i.acf.is_contest_item) {
              return <PopularNavLink key={i.ID} title={i.title} />;
            }
          })}

          <Search />

          {listEndSlot}
        </div>
      </motion.nav>
    );
  }
);

HeaderNavigation.displayName = 'HeaderNavigation';

const cvaRoot = cva([
  'HeaderNavigation-cvaRoot',
  'relative',
  'flex items-center md-max:flex-grow',
  'rounded-button',
  'outline outline-[0.2rem] outline-cBlack/40 sm-max:outline-none',
  'bg-[RGBA(64,64,64,0.5)]',
  'shadow-menu',
]);

const cvaList = cva([
  'HeaderNavigation-cvaList',
  'flex items-center',
  'md-max:grow',
]);

export default HeaderNavigation;
