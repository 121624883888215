import { axiosInstance } from '@/shared/api/axios';
import { API } from '@/shared/api/api';
import { IHeaderOptions } from '@/shared/api/options/getHeaderOptions/types';

interface Params {
  lang: string;
}

export const getHeaderOptions = async (params: Params) => {
  const response = await axiosInstance.get<IHeaderOptions>(
    API.getHeaderOptions,
    {
      params,
    }
  );

  return response.data;
};
