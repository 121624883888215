import { useQuery } from '@tanstack/react-query';
import { getNotViewedNotificationsCount } from '@/shared/api/notify/getNotViewedNotificationsCount';

type Params = Parameters<typeof getNotViewedNotificationsCount>[0];

export const useGetNotViewedNotificationsCount = (params: Params) => {
  return useQuery({
    queryFn: () => getNotViewedNotificationsCount(params),
    queryKey: getUseGetNotViewedNotificationsCountQueryKey(params),
    select: (data) => data.result,
  });
};

export const getUseGetNotViewedNotificationsCountQueryKey = (
  params: Params
) => ['getNotViewedNotificationsCount', params];
