import { QueryClient, useQuery } from '@tanstack/react-query';
import { getHeaderMenu } from '@/shared/api/menus/getHeaderMenu';

type Params = Parameters<typeof getHeaderMenu>[0];

export const useGetHeaderMenu = (params: Params) => {
  return useQuery({
    queryKey: getUseGetHeaderMenuQueryKey(params),
    queryFn: () => getHeaderMenu(params),
    staleTime: Infinity,
  });
};

export const prefetchGetHeaderMenu = async (
  queryClient: QueryClient,
  params: Params
) => {
  await queryClient.prefetchQuery({
    queryKey: getUseGetHeaderMenuQueryKey(params),
    queryFn: () => getHeaderMenu(params),
  });
};

export const getUseGetHeaderMenuQueryKey = (params: Params) => [
  'getHeaderMenu',
  params,
];
