import { API } from '../../api';
import { axiosInstance } from '../../axios';
import { IPhotoCategory } from '@/shared/api/photo/getPhotoCategories/types';

interface Params {
  lang: string;
}

export const getPhotoCategories = async (params: Params) => {
  const result = await axiosInstance.get<IPhotoCategory[]>(
    API.getPhotoCategories,
    { params }
  );

  return result.data;
};
