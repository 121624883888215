'use client';
import { FC, ReactNode } from 'react';
import { cva } from 'class-variance-authority';
import useGetMe from '@/shared/hooks/useGetMe';
import { useLocale } from 'next-intl';
import clsx from 'clsx';
import { AuthPopupButton } from '@/features/AuthPopup/AuthPopupButton';

interface Props {
  className?: string;
  children?: ReactNode;
}

const HeaderButtonsBase: FC<Props> = ({ className, children }) => {
  const locale = useLocale();
  const { isAuth } = useGetMe({ lang: locale });

  return (
    <div className={clsx(cvaRoot(), className)}>
      {isAuth ? children : <AuthPopupButton className={cvaAuthPopupButton()} />}
    </div>
  );
};

const cvaRoot = cva([
  'HeaderButtonsBase-cvaRoot',
  'flex gap-1.2 items-center sm-max:gap-0.8 sm-max:justify-center',
  'h-full',
]);

const cvaAuthPopupButton = cva([
  'HeaderButtonsBase-cvaAuthPopupButton',
  'h-full sm-max:flex-1',
  'px-2.8',
]);

export default HeaderButtonsBase;
