import { FC, ReactNode, DragEvent, DragEventHandler } from 'react';
import { cva } from 'class-variance-authority';
import { InputProps } from 'react-html-props';

interface Props
  extends Omit<
    InputProps,
    'onDrop' | 'onDragOver' | 'onDragEnter' | 'onDragLeave'
  > {
  className?: string;
  children?: ReactNode;
  onDrop?: DragEventHandler<HTMLLabelElement>;
}

const AddFile: FC<Props> = ({
  onDrop,
  children,
  disabled,
  className,
  ...props
}) => {
  const handleDragOver = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
  };

  const handleDragEnter = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
  };

  const handleDragLeave = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
  };

  return (
    <label
      className={className}
      onDrop={disabled ? undefined : onDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}>
      <input
        type={'file'}
        disabled={disabled}
        className={cvaInput()}
        accept={'image/jpeg, image/png'}
        {...props}
      />
      {children}
    </label>
  );
};

const cvaInput = cva([
  'PreviewImageItems-cvaInput',
  'hidden',
  '[&+*]:pointer-events-none',
]);

export default AddFile;
