import { FC, useState } from 'react';
import { cva } from 'class-variance-authority';
import WrapperIcon from '@/shared/ui/WrapperIcon/WrapperIcon';
import SettingsIcon from '~/icons/settings.svg';
import DefaultPopover from '@/entities/DefaultPopover/DefaultPopover';
import { useLogout } from '@/shared/hooks/useLogout';
import clsx from 'clsx';
import { LocalizedLink } from '@/shared/i18n/routing';
import { useLocale } from 'next-intl';
import { useGetHeaderOptions } from '@/shared/hooks/useGetHeaderOptions';
import { useMedia } from 'react-use';

interface Props {
  className?: string;
}

export const ProfileEditPopover: FC<Props> = ({ className }) => {
  const locale = useLocale();

  const isSm = useMedia('(max-width: 767px)', false);

  const { data: headerOptions } = useGetHeaderOptions({ lang: locale });

  const [isProfileEditPopoverOpen, setIsProfileEditPopoverOpen] =
    useState(false);

  const { mutate: logout, isPending: isLogoutPending } = useLogout();

  return (
    <DefaultPopover
      className={cvaRoot()}
      innerClassName={clsx(cvaInnerPopover(), className)}
      isOpen={isProfileEditPopoverOpen}
      offset={[0, isSm ? 20 : 10]}
      onClose={() => setIsProfileEditPopoverOpen(false)}
      onOpen={() => setIsProfileEditPopoverOpen(true)}
      render={
        <>
          <LocalizedLink className={cvaEditButton()} href={'/profile/edit'}>
            {headerOptions?.settings_account.button_edit_profile_text}
          </LocalizedLink>
          <LocalizedLink
            className={cvaEditButton()}
            href={'/profile/edit-photos'}>
            {headerOptions?.settings_account.button_edit_photo_text}
          </LocalizedLink>
          <button
            className={cvaExitButton()}
            type={'button'}
            disabled={isLogoutPending}
            onClick={() => logout({ lang: locale })}>
            {headerOptions?.settings_account.button_logout_text}
          </button>
        </>
      }>
      <div className={cvaWrapperSettings()}>
        <WrapperIcon
          className="md-max:bg-cGray900"
          isStatic
          theme={isProfileEditPopoverOpen ? 'active' : 'primary'}>
          <SettingsIcon />
        </WrapperIcon>
      </div>
    </DefaultPopover>
  );
};

const cvaRoot = cva([
  'ProfileEditPopover-cvaRoot',
  '!z-50',
  'sm-max:w-full',
  'sm-max:px-1.6',
]);

const cvaInnerPopover = cva(['ProfileEditPopover-cvaInnerPopover']);

const cvaWrapperSettings = cva([
  'ProfileEditPopover-cvaWrapperSettings',
  'p-0.8 border-2 border-cPurple900 rounded-[50%] md-max:p-0 md-max:border-none',
]);

const cvaEditButton = cva([
  'ProfileEditPopover-cvaEditButtons',
  'font-semibold',
  'px-0.8 mx-1.2 py-1.4 block',
  'border-b border-cGray700',
]);

const cvaExitButton = cva([
  'ProfileEditPopover-cvaExitButton',
  'font-semibold text-cOrange700',
  'px-0.8  mx-1.2 py-1.4 block',
]);
