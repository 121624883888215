import { FC, useState } from 'react';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { useGetHeaderOptions } from '@/shared/hooks/useGetHeaderOptions';
import Button from '@/shared/ui/Button/Button';
import AuthPopup from '@/features/AuthPopup/AuthPopup';
import { useLocale } from 'next-intl';

interface Props {
  className?: string;
}

export const AuthPopupButton: FC<Props> = ({ className }) => {
  const locale = useLocale();

  const { data: headerOptions } = useGetHeaderOptions({ lang: locale });

  const [isOpenAuth, setOpenAuth] = useState(false);

  return (
    <>
      <Button
        className={clsx(cvaRoot(), className)}
        theme={'primary'}
        size={'initial'}
        onClick={() => setOpenAuth(true)}>
        {headerOptions?.settings_account.button_signin_text}
      </Button>

      <AuthPopup isOpen={isOpenAuth} onClose={() => setOpenAuth(false)} />
    </>
  );
};

const cvaRoot = cva(['AuthPopupButton-cvaRoot', 'shadow-menu']);
