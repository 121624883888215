import { QueryClient, useQuery } from '@tanstack/react-query';
import { getOurProjectsMenu } from '@/shared/api/menus/getOurProjectsMenu';

type Params = Parameters<typeof getOurProjectsMenu>[0];

export const useGetOurProjectsMenu = (params: Params) => {
  return useQuery({
    queryKey: getUseGetOurProjectsMenuQueryKey(params),
    queryFn: () => getOurProjectsMenu(params),
    staleTime: Infinity,
  });
};

export const prefetchGetOurProjectsMenu = async (
  queryClient: QueryClient,
  params: Params
) => {
  await queryClient.prefetchQuery({
    queryKey: getUseGetOurProjectsMenuQueryKey(params),
    queryFn: () => getOurProjectsMenu(params),
  });
};

export const getUseGetOurProjectsMenuQueryKey = (params: Params) => [
  'getOurProjectsMenu',
  params,
];
