'use client';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import './styles.css';
import Dropdown from '../Dropdown/Dropdown';
import { IDropdownItem } from '@/entities/Dropdown/Dropdown';
import WrapperIcon from '@/shared/ui/WrapperIcon/WrapperIcon';
import { useMedia } from 'react-use';

/* TODO: Ограничить в высоте, при переполнении скроллить. Например в шапке */

export interface INavigationDropdownItem extends IDropdownItem {
  selectedTitle?: ReactNode;
}

interface NavigationDropdownProps {
  className?: string;
  buttonCategoryClassName?: string;
  titleClassName?: string;
  title: string;
  items: INavigationDropdownItem[];
  selected: INavigationDropdownItem | null;
  onChange?: (v: INavigationDropdownItem) => void;
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
  isActivated?: boolean;
  variantIcon?: 'figure';
  emptyTitle: string;
  innerIcon?: ReactNode;
}

const NavigationDropdown: FC<NavigationDropdownProps> = ({
  className,
  buttonCategoryClassName,
  titleClassName,
  selected,
  isActivated = false,
  items,
  innerIcon,
  variantIcon,
  title,
  isOpen,
  emptyTitle,
  onChange,
  onClose,
  onOpen,
}) => {
  const isXs = useMedia('(max-width: 519px)', false);

  const variantSvg = isOpen ? 'active' : 'primary';

  return (
    <Dropdown
      className={clsx(cvaRoot(), className)}
      buttonClassName={'w-full'}
      tooltipClassName={cvaTooltip()}
      placement={isXs ? 'bottom' : 'bottom-start'}
      selected={selected}
      items={items}
      isOpen={isOpen}
      onChange={onChange}
      onClose={onClose}
      onOpen={onOpen}>
      <button className={cvaButton({ active: isActivated })} onClick={onOpen}>
        <WrapperIcon
          isStatic
          variant={variantIcon}
          theme={isActivated ? 'inverse' : variantSvg}
          className={cvaButtonIcon()}>
          {innerIcon}
        </WrapperIcon>
        <div className={cvaButtonWrapper()}>
          <div className={clsx(cvaButtonTitle(), titleClassName)}>{title}</div>
          <div
            className={clsx(
              cvaButtonCategory({ isActivated }),
              buttonCategoryClassName
            )}
            suppressHydrationWarning>
            {selected ? selected.selectedTitle ?? selected.title : emptyTitle}
          </div>
        </div>
      </button>
    </Dropdown>
  );
};

const cvaRoot = cva([
  'NavigationDropdown-cvaRoot',
  'relative md-max:flex-grow',
]);

const cvaTooltip = cva([
  'NavigationDropdown-cvaTooltip',
  '!z-50 xs-max:w-full xs-max:px-1.6',
]);

const cvaButton = cva(
  [
    'NavigationDropdown-cvaButton',
    'flex items-center gap-1.2 md-max:flex-grow sm-max:gap-0.8',
    'p-0.6 pr-2.2 sm-max:p-0.2 sm-max:pr-1.8',
    'rounded-button',
    'cursor-pointer',
    'md-max:w-full',
  ],
  {
    variants: {
      active: {
        true: 'bg-cPurple700 border-cPurple700 border-2',
        false: 'border-cPurple900 border-2 bg-cBaseBlack',
      },
    },
  }
);

const cvaButtonIcon = cva([
  'NavigationDropdown-cvaButtonIcon',
  'flex items-center justify-center',
  'w-4.8 h-4.8',
  'rounded-full',
]);

const cvaButtonWrapper = cva(['NavigationDropdown-cvaButtonWrapper']);

const cvaButtonTitle = cva([
  'NavigationDropdown-cvaButtonTitle',
  'text-cWhite text-1.6-600 text-left [&>span]:sm-max:hidden',
  'mb-0.2',
]);

const cvaButtonCategory = cva(
  ['NavigationDropdown-cvaButtonCategory', 'text-1.4-600 text-left truncate'],
  {
    variants: {
      isActivated: {
        true: 'text-[#FFA1D5]',
        false: 'text-cPurple500',
      },
    },
  }
);

export default NavigationDropdown;
