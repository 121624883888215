import { FC, useMemo } from 'react';
import NavLink from '@/entities/NavLink/NavLink';
import { useGetPhotoCategories } from '@/shared/hooks/useGetPhotoCategories';
import useQueryParam from '@/shared/hooks/useQueryParam';
import { IDropdownItem } from '@/entities/Dropdown/Dropdown';
import StartIcon from '~/icons/start-looking.svg';
import { useParams } from 'next/navigation';
import AllIcon from '~/icons/navigation-all.svg';
import clsx from 'clsx';
import { IPhotoCategoryContest } from '@/shared/api/photo/getPhotoCategories/types';
import {
  LocalizedLink,
  useLocalizedPathname,
  useLocalizedRouter,
} from '@/shared/i18n/routing';
import { useLocale } from 'next-intl';
import { useGetHeaderOptions } from '@/shared/hooks/useGetHeaderOptions';
import { cva } from 'class-variance-authority';

interface Props {
  className?: string;
  title: string;
}

export const ContestNavLink: FC<Props> = ({ className, title }) => {
  const router = useLocalizedRouter();
  const locale = useLocale();
  const pathname = useLocalizedPathname();

  const params = useParams<{ slug?: string }>();
  const isActive = pathname.includes('/contest');
  const isVisibleOnMobile = isActive;

  const { data: headerOptions } = useGetHeaderOptions({ lang: locale });

  const { data: contestCategories } = useGetPhotoCategories(
    {
      lang: locale,
    },
    {
      type: 'contest',
    }
  );

  const [selectedParam, setSelectedParam] = useQueryParam<string | undefined>(
    'category'
  );

  const items = useMemo<IDropdownItem[]>(() => {
    return [
      { id: 'all', title: headerOptions?.settings_header_contest.all ?? '' },
      {
        id: 'active',
        title: headerOptions?.settings_header_contest.active ?? '',
      },
      {
        id: 'completed',
        title: headerOptions?.settings_header_contest.completed ?? '',
      },
      ...(contestCategories ?? []).map((i) => ({
        id: (i.contest as IPhotoCategoryContest)?.ID,
        title: i.name,
        icon: <StartIcon className={'w-2.4 h-2.4'} />,
      })),
    ];
  }, [contestCategories]);

  const selected = useMemo<IDropdownItem | null>(() => {
    if (isActive && params.slug)
      return items.find((i) => String(i.id) === params.slug)!;

    return items.find((i) => String(i.id) === selectedParam) ?? items[0];
  }, [params.slug, items, selectedParam, isActive]);

  const onChange = (item: IDropdownItem) => {
    const shouldNavigate = Boolean(
      contestCategories?.find(
        (i) => (i.contest as IPhotoCategoryContest).ID === item.id
      )
    );

    if (shouldNavigate) {
      router.push(`/contest/${item.id}`);
    } else {
      setSelectedParam(() => {
        if (String(item.id) === 'all') return undefined;
        return String(item.id);
      });
    }
  };

  const isLink = Boolean(isActive && params.slug);

  const navLink = (
    <NavLink
      className={clsx(cvaRoot({ isLink, isVisibleOnMobile }), className)}
      item={{
        title: title,
        emptyTitle: headerOptions?.settings_header_contest.all,
        href: '/contest',
        count: contestCategories?.length || undefined,
        variantIcon: 'figure',
        isActive,
        isActivated: Boolean(isActive && params.slug),
        items,
      }}
      iconSlot={
        isActive && params.slug ? (
          <span className={'relative'}>{contestCategories?.length ?? 0}</span>
        ) : (
          <AllIcon />
        )
      }
      selected={selected}
      onChange={onChange}
    />
  );

  if (isLink) {
    return (
      <LocalizedLink className={cvaLink()} href={'/contest'}>
        {navLink}
      </LocalizedLink>
    );
  }

  return navLink;
};

const cvaRoot = cva(['ContestNavLink-cvaRoot'], {
  variants: {
    isLink: {
      true: 'pointer-events-none',
    },
    isVisibleOnMobile: {
      true: 'md-max:flex',
      false: 'md-max:hidden',
    },
  },
});

const cvaLink = cva([
  'ContestNavLink-cvaLink',
  'w-full',
  '[&+*]:before:hidden',
]);
