import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface InitialState {
  isBurgerOpen: boolean;
  setIsBurgerOpen: (state: boolean) => void;
}

export const useHeaderStore = create<InitialState>()(
  immer((set) => ({
    isBurgerOpen: false,
    setIsBurgerOpen: (state) => {
      set((store) => {
        store.isBurgerOpen = state;
      });
    },
  }))
);
