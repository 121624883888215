import { API } from '../../api';
import { axiosInstance } from '../../axios';
import { IOauthURL } from '@/shared/api/auth/getOauthURL/types';

interface IParams {
  redirect_url: string;
  lang: string;
}

export const getOAuthURL = async (params: IParams) => {
  const result = await axiosInstance.get<IOauthURL>(API.getOAuthURL, {
    params,
  });

  return result.data;
};
