import { FC, useMemo } from 'react';
import NavLink from '@/entities/NavLink/NavLink';
import { useGetPhotoCategories } from '@/shared/hooks/useGetPhotoCategories';
import useQueryParam from '@/shared/hooks/useQueryParam';
import { IDropdownItem } from '@/entities/Dropdown/Dropdown';
import SelectedIcon from '~/icons/navigation-selected.svg';
import AllIcon from '~/icons/navigation-all.svg';
import { useLocalizedPathname } from '@/shared/i18n/routing';
import { useGetHeaderOptions } from '@/shared/hooks/useGetHeaderOptions';
import { useLocale } from 'next-intl';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';

interface Props {
  className?: string;
  title: string;
}

export const PopularNavLink: FC<Props> = ({ className, title }) => {
  const locale = useLocale();
  const pathname = useLocalizedPathname();
  const isActive = pathname === '/popular';
  const isVisibleOnMobile = isActive;

  const { data: headerOptions } = useGetHeaderOptions({ lang: locale });

  const { data: photoCategories } = useGetPhotoCategories(
    {
      lang: locale,
    },
    {
      type: 'photo',
    }
  );

  const [selectedParam, setSelectedParam] = useQueryParam<string | undefined>(
    'category'
  );

  const items = useMemo<IDropdownItem[]>(() => {
    return [
      {
        id: 'all',
        title: headerOptions?.settings_header_popular.all_category_text ?? '',
      },
      ...(photoCategories ?? []).map((i) => ({
        id: i.id,
        title: i.name,
      })),
    ];
  }, [photoCategories]);

  const selected = useMemo<IDropdownItem | null>(() => {
    return items.find((i) => String(i.id) === selectedParam) ?? items[0];
  }, [items, selectedParam]);

  const onChange = (item: IDropdownItem) => {
    setSelectedParam(() => (item.id === 'all' ? undefined : String(item.id)));
  };

  return (
    <NavLink
      className={clsx(cvaRoot({ isVisibleOnMobile }), className)}
      item={{
        title,
        emptyTitle: headerOptions?.settings_header_popular.all_category_text,
        href: '/popular',
        isActive,
        items,
      }}
      iconSlot={selectedParam ? <SelectedIcon /> : <AllIcon />}
      selected={selected}
      onChange={onChange}
    />
  );
};

const cvaRoot = cva(['PopularNavLink-cvaRoot'], {
  variants: {
    isVisibleOnMobile: {
      true: 'md-max:flex',
      false: 'md-max:hidden',
    },
  },
});
