import { useMutation, useQueryClient } from '@tanstack/react-query';
import { logout } from '@/shared/api/auth/logout';
import { IGetCurrentUserResponse } from '@/shared/api/user/getMe/types';
import { useLocalizedRouter } from '@/shared/i18n/routing';
import { getUseGetMeQueryKey } from '@/shared/hooks/useGetMe';
import { useAuth } from '@/shared/providers/AuthProvider';
import { useLocale } from 'next-intl';

export const useLogout = () => {
  const router = useLocalizedRouter();
  const locale = useLocale();

  const queryClient = useQueryClient();

  const { setToken } = useAuth();

  return useMutation({
    mutationFn: logout,
    mutationKey: getUseLogoutMutationKey(),
    onSuccess: () => {
      router.push('/');
      setToken('');

      void queryClient.setQueryData<IGetCurrentUserResponse>(
        getUseGetMeQueryKey({ lang: locale }),
        {
          success: true,
          result: null,
        }
      );
    },
  });
};

export const getUseLogoutMutationKey = () => ['logout'];
