import { FC, ReactNode, useState } from 'react';
import { cva } from 'class-variance-authority';
import { IDropdownItem } from '../Dropdown/Dropdown';
import NavigationDropdown from '@/entities/NavigationDropdown/NavigationDropdown';
import WrapperIcon from '@/shared/ui/WrapperIcon/WrapperIcon';
import clsx from 'clsx';
import { LocalizedLink } from '@/shared/i18n/routing';

export interface IItemNavLink {
  title: string;
  href: string;
  isActive: boolean;
  isActivated?: boolean;
  icon?: ReactNode;
  count?: number;
  emptyTitle?: string;
  variantIcon?: 'figure';
  items?: IDropdownItem[];
}

interface Props {
  className?: string;
  item: IItemNavLink;
  iconSlot?: ReactNode;
  selected?: IDropdownItem | null;
  onChange?: (v: IDropdownItem) => void;
}

const NavLink: FC<Props> = ({
  className,
  item,
  iconSlot,
  selected = null,
  onChange,
}) => {
  const [isOpen, setOpen] = useState(false);

  return item.isActive && item.items ? (
    <NavigationDropdown
      className={clsx(cvaRoot({ active: item.isActive }), className)}
      titleClassName={'whitespace-nowrap'}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOpen={isOpen}
      emptyTitle={item.emptyTitle || ''}
      selected={selected}
      variantIcon={item.variantIcon}
      isActivated={item.isActivated}
      title={item.title}
      onChange={onChange}
      items={item.items}
      innerIcon={iconSlot}
    />
  ) : (
    <LocalizedLink
      className={clsx(
        cvaRoot({ active: item.isActive }),
        cvaListItem({ active: item.isActive }),
        className
      )}
      href={item.href}>
      {item.icon && <WrapperIcon theme={'black'}>{item.icon}</WrapperIcon>}
      {item.count && (
        <WrapperIcon
          theme={'black'}
          isStatic
          variant={'figure'}
          className={cvaListItemCount()}>
          <span className={'relative'}>{item.count}</span>
        </WrapperIcon>
      )}
      <span className={cvaListItemTitle({ isActivated: item.isActivated })}>
        {item.title}
      </span>
    </LocalizedLink>
  );
};

const cvaRoot = cva(['NavLink-cvaRoot', 'flex-1'], {
  variants: {
    active: {
      true: '[&+*]:before:hidden ',
    },
  },
});

const cvaListItem = cva(
  [
    'NavLink-cvaListItem',
    'flex items-center',
    'p-0.6 sm-max:p-0.2',
    'group',
    'relative',
    'before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2',
    'before:w-0.2 before:h-1.6 before:bg-cWhite before:opacity-15 before:rounded-lg',
    'first:before:hidden',
  ],
  {
    variants: {
      active: {
        true: 'bg-black rounded-full border-2 border-cPurple900 before:hidden',
      },
    },
  }
);

const cvaListItemCount = cva([
  'NavLink-cvaListItemCount',
  'text-cWhite text-[1.8rem] font-extrabold leading-[4.8rem]',
]);

const cvaListItemTitle = cva(
  [
    'NavLink-cvaListItemTitle',
    'text-1.6-600 whitespace-nowrap',
    'py-1.3 px-1.6',
    'transition-all duration-300',
    'group-hover:text-cPurple500',
  ],
  {
    variants: {
      isActivated: {
        true: 'text-[#FFA1D5]',
        false: 'text-cWhite',
      },
    },
  }
);

export default NavLink;
