import { API } from '../../api';
import { axiosInstance } from '../../axios';

interface Params {
  lang: string;
}

export const getNotViewedNotificationsCount = async (params: Params) => {
  const result = await axiosInstance.get<Response>(
    API.getNotViewedNotificationsCount,
    { params }
  );

  return result.data;
};

interface Response {
  result: number;
}
