import { FC, useEffect } from 'react';
import { cva } from 'class-variance-authority';
import ConnectionArrows from '~/icons/connection-arrows.svg';
import DefaultPopup from '@/entities/DefaultPopup/DefaultPopup';
import { Heading } from '@/shared/ui/Heading/Heading';
import { useSearchParams } from 'next/navigation';
import useGetMe from '@/shared/hooks/useGetMe';
import { useGetGlobalOptions } from '@/shared/hooks/useGetGlobalOptions';
import { useLocale } from 'next-intl';
import Image from 'next/image';
import { useAuth } from '@/shared/providers/AuthProvider';
import { useGetOAuthURL } from '@/shared/hooks/useGetOAuthURL';
import { useGetLocalesConfig } from '@/shared/hooks/useGetLocalesConfig';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onClickButton?: () => void;
}

const AuthPopup: FC<Props> = ({ isOpen, onClose }) => {
  const locale = useLocale();
  const params = useSearchParams();

  const { data: localesConfig } = useGetLocalesConfig();
  const { data: globalOptions } = useGetGlobalOptions({ lang: locale });

  const { setCodeVerifier, setRedirectUrl } = useAuth();

  const { isAuth, isLoading } = useGetMe({ lang: locale });

  const { data: OAuthURL, isFetching } = useGetOAuthURL(
    {
      redirect_url:
        process.env.NEXT_PUBLIC_FRONT_BASE_URL +
        '/' +
        (locale === localesConfig?.defaultLocale ? '' : locale + '/'),
      lang: locale,
    },
    {
      enabled: Boolean(!isLoading && !isAuth && localesConfig),
    }
  );

  useEffect(() => {
    if (OAuthURL && !params.get('code')) {
      setCodeVerifier(OAuthURL.code_verifier);
      setRedirectUrl(OAuthURL.redirect_url);
    }
  }, [OAuthURL]);

  return (
    <DefaultPopup
      innerClassName={cvaInnerPopup()}
      wrapperClassName={cvaWrapper()}
      className={cvaPopup()}
      onClose={onClose}
      gapItems={'sm'}
      button={globalOptions?.settings_auth.button_continue_text}
      withoutCancel
      withClose
      onClick={() => window.open(OAuthURL?.url, '_self')}
      isLoading={isFetching}
      isOpen={isOpen}>
      <div className={cvaConnection()}>
        {globalOptions?.settings_auth.logo_left ? (
          <Image
            className={'w-4.8 h-4.8'}
            src={globalOptions?.settings_auth.logo_left.url}
            alt={globalOptions?.settings_auth.logo_left.alt ?? ''}
            quality={100}
            width={globalOptions?.settings_auth.logo_left.width}
            height={globalOptions?.settings_auth.logo_left.height}
          />
        ) : null}
        <ConnectionArrows className={'w-2.4 h-2.4'} />
        {globalOptions?.settings_auth.logo_right ? (
          <Image
            className={'w-4.8 h-4.8'}
            src={globalOptions?.settings_auth.logo_right.url}
            alt={globalOptions?.settings_auth.logo_right.alt ?? ''}
            quality={100}
            width={globalOptions?.settings_auth.logo_right.width}
            height={globalOptions?.settings_auth.logo_right.height}
          />
        ) : null}
      </div>
      <Heading size={'h3'} level="4">
        {globalOptions?.settings_auth.title}
      </Heading>
      <p className="text-1.8-600">{globalOptions?.settings_auth.text}</p>
    </DefaultPopup>
  );
};

const cvaConnection = cva([
  'AuthPopup-cvaConnection',
  'p-0.8',
  'flex items-center gap-0.8',
]);

const cvaPopup = cva(['AuthPopup-cvaPopup', 'sm-max:w-full']);

const cvaInnerPopup = cva(['AuthPopup-cvaInnerPopup', 'w-full sm-max:w-full']);

const cvaWrapper = cva(['AuthPopup-cvaWrapper', 'w-[42.8rem] sm-max:w-full']);

export default AuthPopup;
