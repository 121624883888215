'use client';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import './styles.css';
import DefaultPopover from '@/entities/DefaultPopover/DefaultPopover';
import DropdownLink from '../DropdownLink/DropdownLink';
import { Placement } from '@popperjs/core';

export interface IDropdownItem {
  id: string | number;
  title: string;
  icon?: ReactNode;
  value?: unknown;
}

interface NavigationDropdownProps {
  className?: string;
  items: IDropdownItem[];
  onChange?: (v: IDropdownItem) => void;
  isOpen: boolean;
  selected?: IDropdownItem | null;
  onClose: () => void;
  onOpen: () => void;
  children: ReactNode;
  placement?: Placement;
  buttonClassName?: string;
  innerClassName?: string;
  tooltipClassName?: string;
}

const Dropdown: FC<NavigationDropdownProps> = ({
  className,
  tooltipClassName,
  onChange,
  selected,
  items,
  buttonClassName,
  innerClassName,
  placement,
  children,
  onClose,
  onOpen,
  isOpen,
}) => {
  return (
    <div className={clsx(cvaRoot(), className)}>
      <DefaultPopover
        className={tooltipClassName}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        buttonClassName={buttonClassName}
        innerClassName={innerClassName}
        placement={placement}
        render={items.map((item) => (
          <DropdownLink
            key={item.id}
            active={selected?.id === item.id}
            item={item}
            onClick={() => {
              onChange?.(item);
              onClose();
            }}
          />
        ))}>
        {children}
      </DefaultPopover>
    </div>
  );
};

const cvaRoot = cva(['Dropdown-cvaRoot', 'relative']);

export default Dropdown;
