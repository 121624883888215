'use client';
import { FC } from 'react';
import FavoritesIcon from '~/icons/favorites-fill.svg';
import WrapperIcon from '@/shared/ui/WrapperIcon/WrapperIcon';
import NotificationsPopover from '@/features/Notifications/ui/NotificationsPopover';
import { ProfileEditPopover } from '@/widgets/Header/ui/ProfileEditPopover';
import { cva } from 'class-variance-authority';
import useGetMe from '@/shared/hooks/useGetMe';
import { useLocale } from 'next-intl';
import { AuthPopupButton } from '@/features/AuthPopup/AuthPopupButton';
import { LocalizedLink, useLocalizedPathname } from '@/shared/i18n/routing';
import Avatar from '@/entities/Avatar/Avatar';

interface Props {}

const HeaderButtonsBurger: FC<Props> = () => {
  const locale = useLocale();
  const pathname = useLocalizedPathname();

  const { isAuth, data: me } = useGetMe({ lang: locale });

  const isProfilePage = pathname.includes('/profile');
  const isMyProfilePage = pathname === `/profile/${me?.result?.ID}`;
  const showAuthButton = !isProfilePage;

  if (!isAuth && !showAuthButton) {
    return null;
  }

  return (
    <div className={cvaRoot()}>
      {isAuth ? (
        <>
          <NotificationsPopover />
          <WrapperIcon href={'/favorites'}>
            <FavoritesIcon />
          </WrapperIcon>

          {isProfilePage && isMyProfilePage ? (
            <ProfileEditPopover />
          ) : (
            <LocalizedLink
              className={
                'flex-shrink-0 basis-6.4 sm-max:basis-4.8 rounded-full overflow-clip'
              }
              href={isAuth ? `/profile/${me!.result!.ID}` : '/profile'}>
              <Avatar
                className={'shadow-menu'}
                src={me?.result?.data?.avatar?.[0]}
                size={64}
              />
            </LocalizedLink>
          )}
        </>
      ) : null}

      {!isAuth && showAuthButton ? (
        <AuthPopupButton className={cvaAuthPopupButton()} />
      ) : null}
    </div>
  );
};

const cvaRoot = cva([
  'HeaderButtonsBurger-cvaRoot',
  'flex gap-1.2 items-center md-max:gap-0.8 sm-max:justify-between',
  'flex-1',
]);

const cvaAuthPopupButton = cva([
  'HeaderButtonsBurger-cvaAuthPopupButton',
  'flex-1',
  'border-2 border-cPurple700',
  'py-2 px-2.8',
  'text-1.8-600',
]);

export default HeaderButtonsBurger;
