'use client';
import { FC, useMemo } from 'react';
import NavLink from '@/entities/NavLink/NavLink';
import { useGetPhotoCategories } from '@/shared/hooks/useGetPhotoCategories';
import useQueryParam from '@/shared/hooks/useQueryParam';
import { INavigationDropdownItem } from '@/entities/NavigationDropdown/NavigationDropdown';
import HurtIcon from '~/icons/hurt.svg';
import StartIcon from '~/icons/start-looking.svg';
import AllIcon from '~/icons/navigation-all.svg';
import SelectedIcon from '~/icons/navigation-selected.svg';
import { useGetHeaderOptions } from '@/shared/hooks/useGetHeaderOptions';
import { useLocale } from 'next-intl';
import { useLocalizedPathname } from '@/shared/i18n/routing';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { useGetHeaderMenu } from '@/shared/hooks/useGetHeaderMenu';

interface Props {
  className?: string;
  title: string;
}

export const VoteNavLink: FC<Props> = ({ className, title }) => {
  const locale = useLocale();
  const pathname = useLocalizedPathname();

  const { data: headerMenu } = useGetHeaderMenu({ lang: locale });
  const staticPagesLinks =
    headerMenu?.items.filter((i) => i.slug).map((i) => `/${i.slug}`) ?? [];

  const isActive = pathname === '/';
  const isVisibleOnTablet =
    isActive ||
    (!pathname.includes('/contest') &&
      !pathname.includes('/popular') &&
      !pathname.includes('/search') &&
      !staticPagesLinks.includes(pathname));
  const isVisibleOnMobile = isVisibleOnTablet && !pathname.includes('/profile');

  const { data: headerOptions } = useGetHeaderOptions({ lang: locale });

  const { data: photoCategories } = useGetPhotoCategories(
    {
      lang: locale,
    },
    {
      type: 'photo',
    }
  );

  const { data: contestCategories } = useGetPhotoCategories(
    {
      lang: locale,
    },
    {
      type: 'contest',
    }
  );

  const [selectedParam, setSelectedParam] = useQueryParam<string | undefined>(
    'category'
  );

  const items = useMemo<INavigationDropdownItem[]>(() => {
    return [
      {
        id: 'all',
        title: headerOptions?.settings_header_vote.all_category_text ?? '',
        selectedTitle: headerOptions?.settings_header_vote.any_category_text,
      },
      ...(contestCategories ?? []).map((i) => ({
        id: i.id,
        title: i.name,
        selectedTitle: `★ ${i.name}`,
        icon: <StartIcon className={'w-2.4 h-2.4'} />,
      })),
      ...(photoCategories ?? []).map((i) => ({
        id: i.id,
        title: i.name,
      })),
    ];
  }, [photoCategories, contestCategories]);

  const selected = useMemo<INavigationDropdownItem | null>(() => {
    return items.find((i) => String(i.id) === selectedParam) ?? items[0];
  }, [items, selectedParam]);

  const onChange = (item: INavigationDropdownItem) => {
    setSelectedParam(() => (item.id === 'all' ? undefined : String(item.id)));
  };

  return (
    <NavLink
      className={clsx(
        cvaRoot({ isVisibleOnTablet, isVisibleOnMobile }),
        className
      )}
      item={{
        title,
        emptyTitle: headerOptions?.settings_header_vote.any_category_text,
        href: '/',
        isActive,
        icon: <HurtIcon className={'w-2.4 h-2.4'} />,
        items,
      }}
      iconSlot={selectedParam ? <SelectedIcon /> : <AllIcon />}
      selected={selected}
      onChange={onChange}
    />
  );
};

const cvaRoot = cva(['VoteNavLink-cvaRoot'], {
  variants: {
    isVisibleOnTablet: {
      true: 'md-max:flex',
      false: 'md-max:hidden',
    },
    isVisibleOnMobile: {
      true: 'sm-max:flex',
      false: 'sm-max:hidden',
    },
  },
});
