import { axiosInstance } from '@/shared/api/axios';
import { API } from '@/shared/api/api';
import { HEADERS } from '@/shared/api/headers';

interface Params {
  notify_ids: number[];
  signal?: AbortSignal;
  lang: string;
}

export const viewNotifications = async ({ signal, lang, ...body }: Params) => {
  const response = await axiosInstance.post(API.viewNotifications, body, {
    params: { lang },
    headers: { ...HEADERS.contentTypeUrlencoded },
    signal,
  });

  return response.data;
};
